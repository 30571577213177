import React from "react"
import { Seo } from "src/component/common/seo"
import Casestudies from "src/component/layout/casestudies"
import { StaticImage } from "gatsby-plugin-image"
import DatastructuringCasestudyList from "src/component/list/datastructuringCasestudyList"

export default function Datastructuring() {
    return (
        <>
         <Casestudies>
          <div className="datastructuring inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_datastructuring.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_datastructuring-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>データ構造化</p>
                                  <span>DATA STRUCTURING</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  AIが文書・画像・音声・IoTなど、散在する非構造化データを即座に整理・構造化。
                                  <br />
                                  すばやく検索・分析可能なデータ分析基盤を構築し、業務効率を飛躍的に改善。
                                  <br className="c-pc" />
                                  迅速で質の高い意思決定を支援します。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIによる<br />データ分析基盤構築で、非構造化データの整理・活用を加速</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>AIで社内外データ統合</h3>
                                 <p>
                                    散在する社内外データをAIが即座に統合・整理し、迅速な分析・活用を支援。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>音声をAIでテキスト化</h3>
                                 <p>
                                    会議や商談の音声をAIが即座に文字化し、キーワード検索で簡単アクセス。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>文書・画像をAIで整理</h3>
                                 <p>
                                    AIが文書や画像を自動分類し、必要な情報を瞬時に探せる環境を構築。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIがメタ情報を自動付与</h3>
                                 <p>
                                    AIがデータのメタ情報を自動生成し、社内のデータ管理・検索効率を向上。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>PDF・動画をAIで構造化</h3>
                                 <p>
                                   非構造化データをAIが分析しやすく変換し、業務での即時活用を可能にする。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <DatastructuringCasestudyList />
                    </div>

                </section>
              </div>
           </Casestudies>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="DATA STRUCTURING"
            description=""
            pathname=""
        />
    )
}